<template>
  <Page class="page-my-profile page-change-my-password">
    <template #title>Cambia password</template>

    <template #default>
      <div class="row">
        <ProfilePasswordChangeForm class="col-md-6 offset-md-3" :title="null" />
      </div>
    </template>
  </Page>
</template>

<script>

import Page from '@/views/components/Private/Page.vue';
import ProfilePasswordChangeForm from '@/views/components/Account/ProfilePasswordChangeForm.vue';

export default {
  components: {
    Page,
    ProfilePasswordChangeForm,
  },
};

</script>
